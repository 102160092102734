import formatDateTime from '@/helper/filter/formatDateTime';

export default {
  computed: {
    formattedProcessLog() {
      const processLogFormatted = JSON.parse(JSON.stringify(this.processLogRaw));

      processLogFormatted
        .sort((a, b) => b.updateDate - a.updateDate)
        .map((entry) => {
          entry.creationDate = formatDateTime(entry.creationDate * 1000);
          entry.updateDate = formatDateTime(entry.updateDate * 1000);
        });

      return processLogFormatted;
    }
  }
};
