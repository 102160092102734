<template>
  <v-timeline v-if="data.length > 0" dense align-top>
    <v-expansion-panels accordion flat class="flex-column">
      <template v-for="(item, index) in data">
        <v-timeline-item
          :key="item.id"
          :color="index === 0 ? 'text' : 'white'"
          :class="{
            'timeline-item--lined': index + 1 < data.length
          }"
        >
          <v-expansion-panel :disabled="!item.comment">
            <v-row no-gutters class="ma-0">
              <v-col class="d-flex">
                <v-expansion-panel-header>
                  <p>{{ item.text }}</p>
                </v-expansion-panel-header>
              </v-col>

              <v-col cols="auto">
                <app-icon-btn
                  v-if="item.documentSource"
                  :loading="loadingFileType === `file_${index}`"
                  class="pdf-btn"
                  icon="icon-file-pdf"
                  size="18"
                  @click.stop="openFile(item.documentSource, `file_${index}`, $t('pdf'))"
                />
              </v-col>
            </v-row>

            <v-expansion-panel-content v-if="item.comment">
              <app-alert color="primary" :icon="false">
                <p class="history__comment" v-html="item.comment"></p>
              </app-alert>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <div class="subtitle-1 grey--text mt-1">{{ item.published | formatDate }}</div>
        </v-timeline-item>
      </template>
    </v-expansion-panels>
  </v-timeline>

  <div v-else>{{ $t('noResults') }}</div>
</template>

<script>
import OpenFileMixin from '@/mixins/OpenFileMixin';

export default {
  name: 'History',

  mixins: [OpenFileMixin],

  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/shared/styles/timeline';

.v-expansion-panels {
  .v-expansion-panel::before {
    box-shadow: none !important;
  }

  .v-expansion-panel--disabled {
    color: var(--c-text);
  }

  .v-expansion-panel-header {
    padding: 0;
    min-height: unset;

    ::v-deep .v-expansion-panel-header__icon {
      align-self: baseline;

      .v-icon {
        color: var(--c-primary);
        margin-top: 3px;
      }
    }
  }

  .v-expansion-panel ::v-deep .v-expansion-panel-content__wrap {
    padding: 8px 0;
  }

  ::v-deep .v-expansion-panel--active {
    .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate) {
      .v-icon {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
      }
    }
  }

  ::v-deep .pdf-btn {
    align-self: baseline;
    margin-top: -8px;
    margin-right: -6px;
  }

  .history__comment {
    white-space: pre-line;
  }
}
</style>
